<template>
    <styled-interface hide-toolbar>
        <template #interface-heading>
            Onboarding
        </template>

        <cancel-onboarding-dialog
            v-model="cancelOnboardingDialogOpen"
            :onboarding="onboardingToCancel"
            @complete="onCancelOnboardingComplete" />

        <styled-card>
            <template #heading>
                Account Onboarding Status
            </template>
            <template #action-buttons>
                <div
                    v-if="onboardingsUpdatedAt"
                    class="grey--text">
                    <em>
                        Updated <time-ago :value="onboardingsUpdatedAt" />
                    </em>
                </div>
                <action-button
                    icon="refresh"
                    :loading="onboardingsLoading"
                    :disabled="onboardingsLoading"
                    @click="refreshOnboardings()">
                    refresh
                </action-button>
            </template>
            <custom-table
                name="onboarding-status"
                min-width="1200px"
                :items="onboardingsData"
                :fields="fields"
                :loading="onboardingsLoading"
                :rows-per-page="50"
                notification=""
                notification-type=""
                initial-sorting="Last touch"
                initial-sort-direction="desc"
                has-filters
                slim-cells />
        </styled-card>
    </styled-interface>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CancelOnboardingDialog from '@/components/globals/CancelOnboardingDialog';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledInterface from '@/components/globals/StyledInterface';
import ActionButton from '@/components/globals/ActionButton';
import StyledCard from '@/components/globals/StyledCard';
import TimeAgo from '@/components/globals/TimeAgo';
import { capitalizeWord } from '@/helpers/capitalizeWord';

export default {
    name: 'AccountOnboardingStatus',
    title: 'Account Onboarding Status',
    components: {
        CancelOnboardingDialog,
        ActionButton,
        StyledCard,
        StyledInterface,
        CustomTable,
        TimeAgo
    },
    data() {
        return {
            fields: [
                {
                    id: 'account',
                    header: 'Account',
                    value: 'dealer.name',
                    align: 'left',
                    width: '14%',
                    sortable: true,
                    type: 'single',
                    filter: 'search'
                },
                {
                    id: 'type',
                    header: 'Type',
                    value: 'type',
                    align: 'left',
                    width: '11%',
                    sortable: true,
                    type: 'single',
                    filter: 'multiple'
                },
                {
                    id: 'label',
                    header: 'Name',
                    value: 'label',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    type: 'single',
                    filter: 'multiple'
                },
                {
                    id: 'created_by',
                    header: 'Created by',
                    value: 'user.name',
                    align: 'left',
                    width: '12%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'created_at',
                    header: 'Started at',
                    value: 'created_at.date',
                    align: 'left',
                    width: '13%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/onboarding/AccountOnboardingDate.vue'),
                },
                {
                    id: 'updated_at',
                    header: 'Last touch',
                    value: 'updated_at.date',
                    align: 'left',
                    width: '13%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/onboarding/AccountOnboardingDate.vue'),
                    props: {
                        type: 'updated'
                    }
                },
                {
                    id: 'left_off',
                    header: 'Left off at',
                    value: 'configuration.currentStep',
                    align: 'left',
                    width: '7%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/onboarding/AccountOnboardingStep.vue'),
                },
                {
                    id: 'cancel',
                    header: '',
                    value: '',
                    align: 'left',
                    width: '5%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/onboarding/AccountOnboardingActions.vue'),
                    props: {
                        type: 'cancel'
                    },
                    callback: (onboarding) => {
                        this.onCancelOnboarding(onboarding);
                    }
                },
                {
                    id: 'continue',
                    header: '',
                    value: '',
                    align: 'left',
                    width: '5%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/onboarding/AccountOnboardingActions.vue'),
                    callback: (onboarding) => {
                        this.onContinueOnboarding(onboarding);
                    }
                },
            ],
            cancelOnboardingDialogOpen: false,
            onboardingToCancel: {}
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            onboardingsLoading: (state) => state.onboarding.onboardingsLoading,
            onboardingsUpdatedAt: (state) => state.onboarding.onboardingsUpdatedAt,
        }),
        ...mapGetters([
            'activeOnboardings'
        ]),
        onboardingsData() {
            return this.activeOnboardings.map(onboarding => {
                onboarding.type = capitalizeWord(onboarding.type);
                onboarding.label = onboarding.label ? onboarding.label : '--';
                return onboarding;
            }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        }
    },
    methods: {
        onContinueOnboarding(onboarding) {
            this.$router.push({
                name: 'dealer-onboarding',
                params: {
                    dealer_id: onboarding.dealer_id,
                    onboarding_id: onboarding.id
                }
            });
        },
        onCancelOnboarding(onboarding) {
            this.onboardingToCancel = onboarding;
            this.cancelOnboardingDialogOpen = true;
        },
        async onCancelOnboardingComplete() {
            this.cancelOnboardingDialogOpen = false;

            if (this.onboardingToCancel.dealer_id == this.currentDealerId) {
                await this.$store.dispatch('updateCurrentDealer');
            }

            this.refreshOnboardings();
        },
        refreshOnboardings() {
            this.$store.dispatch('initActiveOnboardings');
        }
    }
};
</script>
